import React, { useState } from "react";
import classNames from "classnames";
import styles from "../styles/navbar.module.scss";
import menu from "../img/menu.svg";
import search from "../img/search.svg";
import closesearch from "../img/close.svg";
import arrow from "../img/arrow.svg";
import logomgi from "../img/logo/logomgi.svg";
import logohb from "../img/logo/Logo_HB_White.svg";
import { useTranslation } from "react-i18next";
import { isharrisbruno } from "../checkharrisbruno";

const Navbar = () => {
  const [isVisiblesearch, setSearch] = useState(false);
  const showsearch = () => setSearch((isVisiblesearch) => !isVisiblesearch);

  const [isSelectedLang, setSelectLang] = useState(false);
  const changeLang = () => setSelectLang((isSelectedLang) => !isSelectedLang);

  const [isFrEn, setFrEn] = useState(false);
  const changeFrEn = () => setFrEn((isFrEn) => !isFrEn);

  const { t, i18n } = useTranslation();
  return (
    <div className={classNames(styles.navbar, "col-12 row")}>
      <div className={classNames("col-1")}></div>
      <div className={classNames(styles.bar, "col-10")}>
        <a href="/" className={classNames(styles.button)}>
          {isharrisbruno() ? (
            <img src={logohb} alt="logo hb" />
          ) : (
            <img src={logomgi} alt="logo mgi" />
          )}
        </a>
        <div className={classNames(styles.bigtitle)}>
          <p>{t("pdfguideline")}</p>
        </div>
        <div className={classNames(styles.langsearch)}>
          <div className={classNames(styles.search)}>
            <input
              className={classNames(
                styles.input,
                isVisiblesearch ? styles.clicked : ""
              )}
              type="text"
            />
            <img
              onClick={showsearch}
              src={!isVisiblesearch ? search : closesearch}
              alt="Search"
            />
          </div>

          <button
            onClick={changeLang}
            className={classNames(styles.changelanguage)}
          >
            {isFrEn ? "En" : "Fr"}
            <img
              className={classNames(!isSelectedLang ? "" : styles.rotate)}
              src={arrow}
              alt="Choose an other language"
            />
            <div
              onClick={changeFrEn}
              className={classNames(
                styles.button,
                !isSelectedLang ? "" : styles.view
              )}
            >
              <p onClick={() => i18n.changeLanguage(isFrEn ? "fr" : "en-US")}>
                {isFrEn ? "Fr" : "En"}
              </p>
            </div>
          </button>

          <a href="#sommaire" className={classNames(styles.menubtn)}>
            <img src={menu} alt="menu" />
          </a>
        </div>
      </div>
      <div className={classNames("col-1")}></div>
    </div>
  );
};

export default Navbar;
