export const translate = {
  en: {
    translation: {
      guidelines: "Guidelines",

      Foil: "Foil",
      Colorgen: "Colors",
      Varnish: "Varnish",
      pdfguideline: "PDF Guidelines",

      general: {
        corps1: "MGI Approve, The digital proof of print",
        corps1hb: "Approve, The digital proof of print",
        corps2:
          "MGI Approve streamlines exchanges and modernizes the traditional draw voucher by making it digital.",
          corps2hb:
          "Approve streamlines exchanges and modernizes the traditional draw voucher by making it digital.",
        corps3:
          "Fast and intuitive, MGI Approve displays all modern finishing techniques using simply the PDF file for printing.",
          corps3hb:
          "Fast and intuitive, Approve displays all modern finishing techniques using simply the PDF file for printing.",
        corps4:
          "The application is also able to interpret the cutting contours and the fold lines included in the PDF file.",
        corps5:
          "This allows it to simulate in 3 Dimension an ultra realistic representation of all types of project from the simple business card to labels or packaging.",
        corps6: "The different techniques",

        corps7:
          "MGI Approve has been designed to allow you to validate the finishing effects on your media before printing.",
          corps7hb:
          "Approve has been designed to allow you to validate the finishing effects on your media before printing.",
        corps8: "To use this tool, simply import a PDF file for printing.",
        corps9:
          "However, this PDF file will have to meet a standard to allow an optimal 3D representation.",

        corps10: "Usage rule",
        corps11: "File in PDF format",
        corps12:
          "The document must be exported in PDF v 1.7 format. Make sure that your different layers are visible (not hidden).",
        corps13: "360 dpi image",
        corps14:
          "The document must contain images in 360 dpi (optimal display)",
        corps15: "Link Fonts",
        corps16: "Fonts must be linked.",
        corps17: "Design advice",
        corps18:
          "The MGI Approve application relies on layers or named shades to extract selective varnish information from digital gilding and colors.",
          corps18hb:
          "The Approve application relies on layers or named shades to extract selective varnish information from digital gilding and colors.",
        corps20: "Maximum Document Size",
        corps21:
          "The document must not exceed the size of 200MB to be correctly interpreted by the software.",
        corps22: "To reduce the size of your document",
        corps23: "It is advisable not to keep",
        linkimg: "the editing functions when exporting.",
      },

      guidelinecalques: {
        corps1: "Use of layers",
        corps2:
          "Build your PDF with different layers. To allow our application to interpret each layer correctly, you just need to separate it into independent layers correctly named.",
        corps3: "Layer Name",
        corps4: "Description",
      },
      guidelineteintes: {
        corps1: "Use of spot colors",
        corps2:
          "Builds its PDF with spot colors. Using this method it is imperative to group all the elements of the color layer in a single layer. Then simply used spot colors (in direct tone) correctly named to represent the other layers.",
        corps3: "Spot colors",
        corps4: "Description",
      },

      Sommaire: {
        structurePDF: "Structure of the PDF",
        decoupe: "Cuts and folds",
        vernis3d: "Selective 3D varnish",
        vernis2d: "Selective 2D varnish",
        dorure3d: "Digital 3D foil",
        dorure2d: "Digital 2D foil",
        color: "Color",
      },

      colors: {
        corps1:
          "Management of quadrichromy and additional colors: Create a layer",
        corps2:
          "and regroup all elements containing color (quadrichromy) in this layer.",
      },

      dorure2d: {
        corps1: "Management of digital foil:",
        corps2:
          'The digital gilding also called  "hot gold" is the application of laminating (foil) on specific areas of your printing.',
        corps3: "Use of layers:",
        corps4: "Create a layer",
        corps5: "Color name+Foil",
        exemple: "example",
        corps6:
          "and group all the gilding elements in this layer. There may be several different Foils layers.",
        corps7: "Using of spot colors:",
        corps8: "Create a spot color ",
        corps9:
          "and associate all the desired elements to this spot color. There can be several different Foils shades.",
      },

      dorure3d: {
        corps1: "Management of digital 3D foil:",
        corps2:
          "3D foil works by applying a layer of selective varnish to highlight certain elements.",
        corps3: "Usage of layers:",
        corps4: "Create a layer",
        corps5: "Color name+Foil",
        corps6:
          "and group all the elements containing the 3D foil in this layer. The height of the foil will be conditioned by the density.",
        corps7: "Usage of spot colors:",
        corps8: "Just create a new spot color",
        corps9:
          "The height of the foil will be conditioned by the density of this color.",
      },

      vernis2d: {
        corps1: "Management of flat varnish layers :",
        corps2:
          "Flat Varnish is a selective layer of varnish present on the PDF in desired locations. There can be multiple named layers or spot colors.",
        corps3: "Usage of layers:",
        corps4: "Create a layer",
        corps5:
          "and group all the elements containing the varnish in this layer.",
        corps6: "Usage of spot colors:",
        corps7:
          "Associate all varnish elements with a spot color in direct tone named",
      },

      vernis3d: {
        corps1: "Management of raised varnish layers :",
        corps2:
          "3D varnish is a selective varnish layer allowing certain elements to be highlighted.",
        corps3: "Usage of layers:",
        corps4: "You should use a layer",
        corps5:
          "and group all the elements containing the 3D varnish in it.The height of the varnish will be conditioned by the density. There can only be one layer.",
        corps6: "Usage of spot colors:",
        corps7: "You just need to create a new spot color",
        corps8:
          "The height of the varnish will be conditioned by the density of this color. There can only be one spot color.",
      },

      decoupe: {
        corps1:
          "In order for our application to take into account the cutting edges of your document, it is important that it be associated with a spot color",
        corps2:
          "If your document contains folding lines, they will need to be associated with a spot color",
        corps3: "to be taken into account.",
      },

      layername: "Layer Name",
      spotcolor: "Spot Colors",

      goldfoil: "Gold Foil",
      silverfoil: "Silver Foil",
      copperfoil: "Copper Foil",
      redfoil: "Red Foil",
      greenfoil: "Green Foil",
      bluefoil: "Blue Foil",
      pinkfoil: "Pink Foil",
      orangefoil: "Orange Foil",
      blackfoil: "Black Foil",
      whitefoil: "White Foil",

      quadri: "Four-color",

      cutcontour: "Cut contour",
      crease: "Crease",

      varnishzone: "Varnish zone",
    },
  },
  fr: {
    translation: {
      guidelines: "Guidelines",

      Foil: "Dorure",
      Colorgen: "Couleurs",
      Varnish: "Vernis",
      pdfguideline: "Guidelines PDF",

      general: {
        corps1: "MGI Approve, Le bon à tirer numérique",
        corps1hb: "Approve, Le bon à tirer numérique",
        corps2:
          "MGI Approve fluidifie les échanges et modernise le bon à tirer traditionnel en le rendant numérique.",
          corps2hb:
          "Approve fluidifie les échanges et modernise le bon à tirer traditionnel en le rendant numérique.",
        corps3:
          "Rapide et intuitif, MGI Approve permet d'afficher l'ensemble des techniques d'ennoblissement modernes en utilisant simplement le fichier PDF destiné à l'impression.",
          corps3hb:
          "Rapide et intuitif, Approve permet d'afficher l'ensemble des techniques d'ennoblissement modernes en utilisant simplement le fichier PDF destiné à l'impression.",
        corps4:
          "L'application est également capable d'interpréter les contours de découpe et les lignes de plis incluses dans le fichier PDF.",
        corps5:
          "Ce qui lui permet de simuler en 3 Dimensions une représentation ultra réaliste de tous types de projets de la simple carte de visite aux étiquettes ou encore aux packagings.",
        corps6: "Les différentes techniques",

        corps7:
          "MGI Approve a été conçu pour vous permettre de valider les effets d’ennoblissement sur votre support avant impression.",
          corps7hb:
          "Approve a été conçu pour vous permettre de valider les effets d’ennoblissement sur votre support avant impression.",
        corps8:
          "Pour utiliser cet outil, il suffira simplement d’importer un fichier au format PDF destiné à l’impression.",
        corps9:
          "Néanmoins, ce fichier PDF devra répondre à un standard pour permettre une représentation 3D optimale.",

        corps10: "Règle d'usage",
        corps11: "Fichier au format PDF",
        corps12:
          "Le document doit impérativement être exporté au format PDF v 1.7. Assurez-vous que vos différents calques soient visibles (non masqué).",
        corps13: "Image 360 dpi",
        corps14:
          "Le document doit contenir des images en 360 dpi (affichage optimal)",
        corps15: "Intégrer les polices",
        corps16: "Les polices de caractère doivent être intégrées.",
        corps17: "Conseil de Conception",
        corps18:
          "L’application MGI Approve s’appuie sur les calques et sur les teintes nommées, afin d’extraire les informations d'ennoblissement numérique (vernis sélectif, dorures à chaud) et de couleurs.",
          corps18hb:
          "L’application Approve s’appuie sur les calques et sur les teintes nommées, afin d’extraire les informations d'ennoblissement numérique (vernis sélectif, dorures à chaud) et de couleurs.",
        corps20: "Taille maximale du document",
        corps21:
          "Le document ne doit pas excéder la taille de 200Mo pour être correctement interprété par le logiciel.",
        corps22: "Pour réduire la taille de votre document",
        corps23: "Il est conseillé de ne pas conserver",
        linkimg: "les fonctions d'édition lors de l'export.",
      },

      guidelinecalques: {
        corps1: "Utilisation des Calques",
        corps2:
          "Construire son PDF avec différents calques. Pour permettre à notre application d’interpréter chaque couche correctement, il suffit de séparer celle-ci dans des calques indépendants correctement nommés.",
        corps3: "Layer Name",
        corps4: "Description",
      },
      guidelineteintes: {
        corps1: "Utilisation teintes nommées",
        corps2:
          "Construire son PDF avec des teintes nommées. En utilisant cette méthode, il faudra impérativement regrouper tous les éléments de la couche couleurs dans un seul et même calque. Puis simplement utiliser des teintes nommées (en ton direct) selon le tableau ci-contre, pour représenter les autres couches.",
        corps3: "TEINTES NOMMées",
        corps4: "Description",
      },

      Sommaire: {
        structurePDF: "Structure du PDF",
        decoupe: "Découpes et plis",
        vernis3d: "Vernis sélectif 3D",
        vernis2d: "Vernis sélectif 2D",
        dorure3d: "Dorure numérique 3D",
        dorure2d: "Dorure numérique 2D",
        color: "Couleurs",
      },

      colors: {
        corps1:
          "Gestion de la quadrichromie et des couleurs additionnelles : créer un calque",
        corps2:
          " et regrouper tous les éléments contenant de la couleur (la quadrichromie) dans ce calque.",
      },

      dorure2d: {
        corps1: "Gestion de la dorure numérique:",
        corps2:
          "La dorure numérique aussi appelée \"dorure à chaud\", c'est l'application de pelliculage (Foil) sur des zones précises de votre impression.",
        corps3: "Utilisation des calques:",
        corps4: "Créer un Calque",
        corps5: "Nom de la couleur+Foil",
        exemple: " exemple",
        corps6:
          "et regrouper tous les éléments de dorure dans ce calque. Il peut y avoir plusieurs calques de Foils différents.",
        corps7: "Utilisation des teintes nommées:",
        corps8: "Créer une teinte nommée ",
        corps9:
          "et associer tous les éléments souhaités à cette teinte. Il peut y avoir plusieurs teintes de Foils différentes. ",
      },

      dorure3d: {
        corps1: "Gestion de la dorure 3D numérique:",
        corps2:
          "La dorure 3D fonctionne en appliquant une couche de vernis sélectif, permettant de mettre en relief certain éléments.",
        corps3: "Utilisation des calques:",
        corps4: "Créer un calque",
        corps5: "Nom de la couleur+Foil",
        corps6:
          "et regrouper tous les éléments contenant la dorure 3D dans ce calque. L'épaisseur de la dorure sera conditionnée par la densité.",
        corps7: "Utilisation des teintes nommées:",
        corps8: "Il suffit simplement de créer une nouvelle teinte nommée ",
        corps9:
          "L'épaisseur de la dorure sera conditionnée par la densité de cette couleur.",
      },

      vernis2d: {
        corps1: "Gestion des couches de vernis à plat :",
        corps2:
          "Le vernis à plat est une couche de vernis sélectif présente sur le PDF aux emplacements souhaités. Il peut y avoir plusieurs calques ou teintes nommées.",
        corps3: "Utilisation des calques:",
        corps4: "Créer un calque",
        corps5:
          "et regrouper tous les éléments contenant le vernis dans ce calque.",
        corps6: "Utilisation des teintes nommées:",
        corps7:
          "Associer tous les éléments de vernis à une teinte nommée en ton direct intitulé ",
      },

      vernis3d: {
        corps1: "Gestion des couches de vernis en relief :",
        corps2:
          "Le vernis 3D est une couche de vernis sélectif permettant de mettre en relief certain éléments.",
        corps3: "Utilisation des calques:",
        corps4: "Il convient d’utiliser un calque",
        corps5:
          "et de regrouper tous les éléments contenant le vernis 3D dans celui-ci. L'épaisseur du vernis sera conditionnée par la densité. Il ne peut y avoir qu’un seul calque.",
        corps6: "Utilisation des teintes nommées:",
        corps7: "Il suffit simplement de créer une nouvelle teinte nommée",
        corps8:
          "L'épaisseur du vernis sera conditionnée par la densité de cette couleur. Il ne peut y avoir qu’une seule teinte nommée.",
      },

      decoupe: {
        corps1:
          "Pour que notre application prenne en compte les contours de découpe de votre document, il est important que celui-ci soit associée à une teinte nommée ",
        corps2:
          "Si votre document contient des lignes de pliage, il faudra les associer à une teinte nommée ",
        corps3: "pour qu'il soit pris en compte.",
      },

      layername: "Nom de calques",
      spotcolor: "Teintes nommées",

      goldfoil: "Dorure Or",
      silverfoil: "Dorure Argent",
      copperfoil: "Dorure Cuivre",
      redfoil: "Dorure Rouge",
      greenfoil: "Dorure Verte",
      bluefoil: "Dorure Bleu",
      pinkfoil: "Dorure Rose",
      orangefoil: "Dorure Orange",
      blackfoil: "Dorure Noir",
      whitefoil: "Dorure Blanche",

      quadri: "Quadrichromie",

      cutcontour: "Contour de découpe",
      crease: "Trait de pliage",

      varnishzone: "Zone de Vernis",
    },
  },
  jpn: {
    translation: {},
  },
};
